import Vue from 'vue';
import loading from '@/components/loading.vue';
var loadingVm = null;
var loadingCount = 0;
export var showLoading = function showLoading() {
  if (!loadingVm) {
    var Instance = Vue.extend(loading);
    loadingVm = new Instance().$mount();
  }
  if (!loadingCount) {
    document.body.appendChild(loadingVm.$el);
  }
  loadingCount++;
};
export var hideLoading = function hideLoading() {
  loadingCount--;
  loadingCount = Math.max(loadingCount, 0);
  if (!loadingCount && loadingVm) {
    loadingVm.$destroy();
    document.body.removeChild(loadingVm.$el);
    loadingVm = null;
  }
};