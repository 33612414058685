export var EXCHANGE_TYPE;
(function (EXCHANGE_TYPE) {
  EXCHANGE_TYPE["LIVE_KOIN"] = "LIVE_KOIN";
  EXCHANGE_TYPE["LIVE_DIAMOND"] = "LIVE_DIAMOND";
  EXCHANGE_TYPE["LIVE_CASH"] = "LIVE_CASH";
  EXCHANGE_TYPE["ACTIVITY_CASH"] = "ACTIVITY_CASH";
  EXCHANGE_TYPE["ACTIVITY_POINT"] = "ACTIVITY_POINT";
  EXCHANGE_TYPE["ACTIVITY_ENERGY"] = "ACTIVITY_ENERGY";
  EXCHANGE_TYPE["LIVE_GAME_COIN"] = "LIVE_GAME_COIN";
  EXCHANGE_TYPE["LIVE_GAME_CASH"] = "LIVE_GAME_CASH";
})(EXCHANGE_TYPE || (EXCHANGE_TYPE = {}));
;