import _defineProperty from "/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _balancePageConfig;
import { COUNTRY_LIST, webCountry } from '@/common/const';
var balancePageConfig = (_balancePageConfig = {}, _defineProperty(_balancePageConfig, COUNTRY_LIST.ARG, {
  offlineBanner: 'https://d3qrssutfqv0j9.cloudfront.net/kos/nlav10586/encourage/balance/offline/es.png',
  unit: '$',
  cash: 'Saldo (ARS)',
  notice: 'Tus balances no retirados se eliminarán si no inicias sesión durante 30 días consecutivos',
  pointsQuestion: '1000 Kwai Golds = 1 Saldo（ARS）Completa diferentes tareas para ganar Kwai Golds. Después puedes canjear Kwai Golds por dinero y retirarlo.',
  regular: 'Descargo de responsabilidad: a. Las Actividades son solo para usuarios individuales que hayan descargado la versión 3.5.3 de Kwai o una posterior de Google Play Store, que actualmente se encuentran y residen en Argentina y que tienen 18 años de edad o más. b. Para demostrar que has cumplido con las condiciones de elegibilidad y ser recompensado por las Actividades, es posible que debas proporcionar tu nombre, cuenta bancaria, número de seguro social y dirección de correo electrónico en cualquier momento. c. La Compañía se reserva el derecho de cambiar, cancelar, terminar o ajustar las Reglas y Actividades en cualquier momento con siete (7) días de anticipación. d. Un “día” mencionado en las Reglas es un día calendario en la zona horaria de Argentina.',
  balanceFAQLink: 'https://ppg.m.kwai-pro.com/block/n/activity/page/hOIKXybQ?inKwaiWK=1&hyId=doodle_hOIKXybQ&webview=yoda',
  ruleLink: 'https://ppg.m.kwai-pro.com/block/n/activity/page/fbVqrzpH?inKwaiWK=1&hyId=doodle_fbVqrzpH'
}), _defineProperty(_balancePageConfig, COUNTRY_LIST.BRA, {
  offlineBanner: 'https://d3qrssutfqv0j9.cloudfront.net/kos/nlav10586/encourage/balance/offline/pt-br.png',
  unit: 'R$',
  cash: 'Saldo (BRL)',
  notice: 'Seu balanço será removido se você não se conectar por 30 dias.',
  pointsQuestion: '10000 Kwai Golds = 1 reais. Complete diferentes objetivos para ganhar Kwai Gold. Você também pode trocar Kwai Gold por dinheiro e sacá-lo.',
  regular: 'Isenção de responsabilidade: a. As Atividades são apenas para usuários individuais que estão atualmente localizados e residem no Brasil e que têm 18 anos ou mais. b. A Empresa se reserva o direito de alterar, cancelar, encerrar ou ajustar as Regras e Atividades a qualquer momento. c. Com o objetivo de comprovar que os usuários cumprem com as condições e elegibilidade para serem recompensados, os Usuários podem precisar fornecer seu nome, conta bancária, CPF e endereço de e-mail a qualquer momento durante a Atividade.d. Um “dia” é um dia corrido no fuso horário de Brasília.',
  balanceFAQLink: 'https://ppg.m.kwai-pro.com/block/n/activity/page/pvNtLhrz?inKwaiWK=1&hyId=doodle_pvNtLhrz&webview=yoda',
  ruleLink: 'https://ppg.m.kwai-pro.com/block/n/activity/page/PBXdouvB?inKwaiWK=1&hyId=doodle_PBXdouvB'
}), _defineProperty(_balancePageConfig, COUNTRY_LIST.CHL, {
  offlineBanner: 'https://d3qrssutfqv0j9.cloudfront.net/kos/nlav10586/encourage/balance/offline/es.png',
  unit: '$',
  cash: 'Saldo (CLP)',
  notice: 'Tus balances no retirados se eliminarán si no inicias sesión durante 30 días consecutivos',
  pointsQuestion: '100 Kwai Golds = 1 Saldo（CLP). Completa diferentes tareas para ganar Kwai Golds. Después puedes canjear Kwai Golds por dinero y retirarlo.',
  regular: 'Descargo de responsabilidad: a. Las Actividades son solo para usuarios individuales que hayan descargado la versión 3.5.3 de Kwai o una posterior de Google Play Store, que actualmente se encuentran y residen en Chile y que tienen 18 años de edad o más. b. Para demostrar que ha cumplido con las condiciones de elegibilidad y ser recompensado por las Actividades, es posible que deba proporcionar su nombre, cuenta bancaria, número de seguro social y dirección de correo electrónico en cualquier momento. c. La Compañía se reserva el derecho de cambiar, cancelar, terminar o ajustar las Reglas y Actividades en cualquier momento con siete (7) días de anticipación. d. Un “día” mencionado en las Reglas es un día calendario en la zona horaria de Chile.',
  balanceFAQLink: 'https://ppg.m.kwai-pro.com/block/n/activity/page/hbHPUyyo?inKwaiWK=1&hyId=doodle_hbHPUyyo&webview=yoda',
  ruleLink: 'https://ppg.m.kwai-pro.com/block/n/activity/page/dPvBrzHF?inKwaiWK=1&hyId=doodle_dPvBrzHF&webview=yoda'
}), _defineProperty(_balancePageConfig, COUNTRY_LIST.COL, {
  offlineBanner: 'https://d3qrssutfqv0j9.cloudfront.net/kos/nlav10586/encourage/balance/offline/es.png',
  unit: '$',
  cash: 'Saldo (COP)',
  notice: 'Tus balances no retirados se eliminarán si no inicias sesión durante 30 días consecutivos.',
  pointsQuestion: '100 Kwai Golds = 1 Saldo（COP). Completa diferentes tareas para ganar Kwai Golds. Después puedes canjear Kwai Golds por dinero y retirarlo.',
  regular: 'Descargo de responsabilidad: a. Las Actividades son solo para usuarios individuales que hayan descargado la versión 3.5.3 de Kwai o una posterior de Google Play Store, que actualmente se encuentran y residen en Colombia y que tienen 18 años de edad o más. b. Para demostrar que ha cumplido con las condiciones de elegibilidad y ser recompensado por las Actividades, es posible que deba proporcionar su nombre, cuenta bancaria, número de seguro social y dirección de correo electrónico en cualquier momento. c. La Compañía se reserva el derecho de cambiar, cancelar, terminar o ajustar las Reglas y Actividades en cualquier momento con siete (7) días de anticipación. d. Un “día” mencionado en las Reglas es un día calendario en la zona horaria de Colombia.',
  balanceFAQLink: 'https://ppg.m.kwai-pro.com/block/n/activity/page/vWhJcpeI?inKwaiWK=1&hyId=doodle_vWhJcpeI&webview=yoda',
  ruleLink: 'https://ppg.m.kwai-pro.com/block/n/activity/page/eRRMOWkH?inKwaiWK=1&hyId=doodle_eRRMOWkH'
}), _defineProperty(_balancePageConfig, COUNTRY_LIST.DOM, {
  offlineBanner: 'https://d3qrssutfqv0j9.cloudfront.net/kos/nlav10586/encourage/balance/offline/es.png',
  unit: 'RD$',
  cash: 'Saldo (DOP)',
  notice: 'Tus balances no retirados se eliminarán si no inicias sesión durante 30 días consecutivos.',
  pointsQuestion: '1000 Kwai Golds = 1 Saldo（DOP). Completa diferentes tareas para ganar Kwai Golds. Después puedes canjear Kwai Golds por dinero y retirarlo.',
  regular: 'Descargo de responsabilidad: a. Las Actividades son solo para usuarios individuales que hayan descargado la versión 3.5.3 de Kwai o una posterior de Google Play Store, que actualmente se encuentran y residen en República Dominicana y que tienen 18 años de edad o más. b. Para demostrar que ha cumplido con las condiciones de elegibilidad y ser recompensado por las Actividades, es posible que deba proporcionar su nombre, cuenta bancaria, número de seguro social y dirección de correo electrónico en cualquier momento. c. La Compañía se reserva el derecho de cambiar, cancelar, terminar o ajustar las Reglas y Actividades en cualquier momento con siete (7) días de anticipación. d. Un “día” mencionado en las Reglas es un día calendario en la zona horaria de República Dominicana.',
  balanceFAQLink: 'https://ppg.m.kwai-pro.com/block/n/activity/page/mjbADdjC?inKwaiWK=1&hyId=doodle_mjbADdjC&webview=yoda',
  ruleLink: 'https://ppg.m.kwai-pro.com/block/n/activity/page/dPvBrzHF?inKwaiWK=1&hyId=doodle_dPvBrzHF&webview=yoda'
}), _defineProperty(_balancePageConfig, COUNTRY_LIST.ECU, {
  offlineBanner: 'https://d3qrssutfqv0j9.cloudfront.net/kos/nlav10586/encourage/balance/offline/es.png',
  unit: '$',
  cash: 'Saldo (USD)',
  notice: 'Tus balances no retirados se eliminarán si no inicias sesión durante 30 días consecutivos.',
  pointsQuestion: '10.000 Kwai Golds = 1 Saldo（USD). Completa diferentes tareas para ganar Kwai Golds. Después puedes canjear Kwai Golds por dinero y retirarlo.',
  regular: 'Descargo de responsabilidad: a. Las Actividades son solo para usuarios individuales que hayan descargado la versión 3.5.3 de Kwai o posterior de Android y versión 5.2.2 o posterior de iOS, que actualmente se encuentran y residen en Ecuador y que tienen 18 años de edad o más. b. Para demostrar que has cumplido con las condiciones de elegibilidad y ser recompensado por las Actividades, es posible que debas proporcionar tu nombre, cuenta bancaria, número de seguro social y dirección de correo electrónico en cualquier momento. c. La Compañía se reserva el derecho de cambiar, cancelar, terminar o ajustar las Reglas y Actividades en cualquier momento con siete (7) días de anticipación. d. Un “día” mencionado en las Reglas es un día calendario en la zona horaria de Ecuador.',
  balanceFAQLink: 'https://ppg.m.kwai-pro.com/block/n/activity/page/JEgRtPan?inKwaiWK=1&hyId=doodle_JEgRtPan&webview=yoda',
  ruleLink: 'https://ppg.m.kwai-pro.com/block/n/activity/page/FExmaxkW?inKwaiWK=1&hyId=doodle_FExmaxkW&webview=yoda'
}), _defineProperty(_balancePageConfig, COUNTRY_LIST.MEX, {
  offlineBanner: 'https://d3qrssutfqv0j9.cloudfront.net/kos/nlav10586/encourage/balance/offline/es.png',
  unit: '$',
  cash: 'Saldo (MXN)',
  notice: 'Tus balances no retirados se eliminarán si no inicias sesión durante 30 días consecutivos.',
  pointsQuestion: '10000 Kwai Golds = 1 Saldo(MXN). Completa diferentes tareas para ganar Kwai Golds. Después puedes canjear Kwai Golds por dinero y retirarlo.',
  regular: 'Descargo de responsabilidad: a. Las Actividades son solo para usuarios individuales que hayan descargado la versión 3.5.3 de Kwai o una posterior de Google Play Store, que actualmente se encuentran y residen en México y que tienen 18 años de edad o más. b. Para demostrar que has cumplido con las condiciones de elegibilidad y ser recompensado por las Actividades, es posible que debas proporcionar tu nombre, cuenta bancaria, número de seguro social y dirección de correo electrónico en cualquier momento. c. La Compañía se reserva el derecho de cambiar, cancelar, terminar o ajustar las Reglas y Actividades en cualquier momento con siete (7) días de anticipación. d. Un “día” mencionado en las Reglas es un día calendario en la zona horaria de México.',
  balanceFAQLink: 'https://ppg.m.kwai-pro.com/block/n/activity/page/sXzliXcj?inKwaiWK=1&hyId=doodle_sXzliXcj&webview=yoda',
  ruleLink: 'https://ppg.m.kwai-pro.com/block/n/activity/page/HCpYMgPe?inKwaiWK=1&hyId=doodle_HCpYMgPe'
}), _defineProperty(_balancePageConfig, COUNTRY_LIST.PER, {
  offlineBanner: 'https://d3qrssutfqv0j9.cloudfront.net/kos/nlav10586/encourage/balance/offline/es.png',
  unit: 'S/',
  cash: 'Saldo (PEN)',
  notice: 'Tus balances no retirados se eliminarán si no inicias sesión durante 30 días consecutivos.',
  pointsQuestion: '10000 Kwai Golds = 1 Saldo(PEN). Completa diferentes tareas para ganar Kwai Golds. Después puedes canjear Kwai Golds por dinero y retirarlo.',
  regular: 'Descargo de responsabilidad: a. Las Actividades son solo para usuarios individuales que hayan descargado la versión 3.5.3 de Kwai o posterior de Android y versión 5.2.2 o posterior de iOS, que actualmente se encuentran y residen en Perú y que tienen 18 años de edad o más. b. Para demostrar que has cumplido con las condiciones de elegibilidad y ser recompensado por las Actividades, es posible que debas proporcionar tu nombre, cuenta bancaria, número de seguro social y dirección de correo electrónico en cualquier momento. c. La Compañía se reserva el derecho de cambiar, cancelar, terminar o ajustar las Reglas y Actividades en cualquier momento con siete (7) días de anticipación. d. Un “día” mencionado en las Reglas es un día calendario en la zona horaria de Perú.',
  balanceFAQLink: 'https://ppg.m.kwai-pro.com/block/n/activity/page/WcUUxbsY?inKwaiWK=1&hyId=doodle_WcUUxbsY',
  ruleLink: 'https://ppg.m.kwai-pro.com/block/n/activity/page/RVMXFshB?inKwaiWK=1&hyId=doodle_RVMXFshB'
}), _defineProperty(_balancePageConfig, COUNTRY_LIST.TUR, {
  offlineBanner: 'https://d3qrssutfqv0j9.cloudfront.net/kos/nlav10586/encourage/balance/offline/tr.png',
  unit: '₺',
  cash: 'Nakit',
  notice: 'Para çekme işleminin iki iş günü içinde yapılması bekleniyor, lütfen sabırla bekleyin',
  pointsQuestion: '10000 Kwai Altın = 1 Lira. Kwai Altın paraya çevrilebilir ve çekilebilir.',
  regular: 'Yasal notlar',
  balanceFAQLink: 'https://ppg.kwai-pro.com/doodle/eEHYFwVy.html?webview=yoda&share_item_type=jimu_',
  ruleLink: ' https://ppg.kwai-pro.com/doodle/CYDGUoPM.html'
}), _defineProperty(_balancePageConfig, COUNTRY_LIST.EGY, {
  cash: 'نقد',
  unit: 'EGP',
  notice: 'إذا لم تقم بتسجيل الدخول إلى حسابك لمدة ٣٠ يوما، فسيتم إفراغ النقود وذهب كواي في حسابك',
  pointsQuestion: ' يمكن تحويل ذهب كواي إلى النقود،1EGP=10000',
  ruleLink: 'https://ppg-m.kwai-app.com/block/n/activity/page/KPxsPxuA?inKwaiWK=1&hyId=doodle_KPxsPxuA&webview=yoda',
  legalLink: 'https://ppg-m.kwai-app.com/block/n/activity/page/aoONiGOI?inKwaiWK=1&hyId=doodle_aoONiGOI'
}), _defineProperty(_balancePageConfig, COUNTRY_LIST.IRQ, {
  cash: 'نقد',
  unit: 'IQD',
  notice: 'إذا لم تقم بتسجيل الدخول إلى حسابك لمدة ٣٠ يوما، فسيتم إفراغ النقود وذهب كواي في حسابك',
  pointsQuestion: ' يمكن تحويل ذهب كواي إلى النقود،1IQD=10000',
  ruleLink: 'https://ppg-m.kwai-app.com/block/n/activity/page/wYGYSAOQ?inKwaiWK=1&hyId=doodle_wYGYSAOQ&webview=yoda',
  legalLink: 'https://ppg-m.kwai-app.com/block/n/activity/page/aoONiGOI?inKwaiWK=1&hyId=doodle_aoONiGOI'
}), _defineProperty(_balancePageConfig, COUNTRY_LIST.DZA, {
  cash: 'نقد',
  unit: 'DZD',
  notice: 'إذا لم تقم بتسجيل الدخول إلى حسابك لمدة ٣٠ يوما، فسيتم إفراغ النقود وذهب كواي في حسابك',
  pointsQuestion: ' يمكن تحويل ذهب كواي إلى النقود،1DZD=10000',
  ruleLink: 'https://ppg-m.kwai-app.com/block/n/activity/page/HVZDKsux?inKwaiWK=1&hyId=doodle_HVZDKsux&webview=yoda',
  legalLink: 'https://ppg-m.kwai-app.com/block/n/activity/page/aoONiGOI?inKwaiWK=1&hyId=doodle_aoONiGOI'
}), _defineProperty(_balancePageConfig, COUNTRY_LIST.JOR, {
  cash: 'نقد',
  unit: 'JOD',
  notice: 'إذا لم تقم بتسجيل الدخول إلى حسابك لمدة ٣٠ يوما، فسيتم إفراغ النقود وذهب كواي في حسابك',
  pointsQuestion: ' يمكن تحويل ذهب كواي إلى النقود،1JOD=10000',
  ruleLink: 'https://ppg-m.kwai-app.com/block/n/activity/page/KPxsPxuA?inKwaiWK=1&hyId=doodle_KPxsPxuA',
  legalLink: 'https://ppg-m.kwai-app.com/block/n/activity/page/aoONiGOI?inKwaiWK=1&hyId=doodle_aoONiGOI'
}), _defineProperty(_balancePageConfig, COUNTRY_LIST.SAU, {
  cash: 'نقد',
  unit: 'SAR',
  notice: 'إذا لم تقم بتسجيل الدخول إلى حسابك لمدة ٣٠ يوما، فسيتم إفراغ النقود وذهب كواي في حسابك',
  pointsQuestion: ' يمكن تحويل ذهب كواي إلى النقود،1SAR=10000',
  ruleLink: 'https://ppg.m.kwai-pro.com/block/n/activity/page/nIOeVfsF?hyId=doodle_nIOeVfsF&webview=yoda',
  legalLink: 'https://ppg-m.kwai-app.com/block/n/activity/page/aoONiGOI?inKwaiWK=1&hyId=doodle_aoONiGOI'
}), _balancePageConfig);
var defaultConfig = {
  offlineBanner: '',
  unit: '',
  cash: 'cash',
  notice: '',
  pointsQuestion: '',
  regular: '',
  balanceFAQLink: '',
  ruleLink: '',
  legalLink: ''
};
export var balancePage = balancePageConfig[webCountry] || defaultConfig;