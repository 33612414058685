import request from '@/services/axios';
import { isInApp } from '@/utils/env';
export var getSwitchData = function getSwitchData(keys) {
  var path = isInApp ? '/rest/o/wallet-v2/switch/app/get' : '/rest/o/wallet-v2/switch/web/get';
  return request.post(path, keys, {
    silent: true
  });
};
export var getUserCountryByUid = function getUserCountryByUid(params) {
  var url = isInApp ? '/rest/o/wallet/recharge/thirdparty/userInfo' : '/rest/o/w/wallet/recharge/thirdparty/userInfo';
  return request.post(url, params);
};