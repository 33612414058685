import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.concat.js";
export var getQuery = function getQuery() {
  var url = window.location.search; // 获取url中"?"符后的字串
  var theRequest = {};
  if (url.indexOf('?') !== -1) {
    var str = url.substr(1);
    var strs = str.split('&');
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1]);
    }
  }
  return theRequest;
};
/**
 * 比较版本号
 * @param  {string} source 比较源
 * @param  {string} target 比较目标
 * @return {inter} 前者大返回>0, 相等返回0, 前者小返回<0
 */
export function compareAppVersion(source, target) {
  var sourceArr = source.split('.');
  var targetArr = target.split('.');
  for (var i = 0; i < sourceArr.length; i++) {
    if (sourceArr[i] !== targetArr[i]) {
      sourceArr[i] = Number.isNaN(sourceArr[i]) ? 0 : sourceArr[i];
      targetArr[i] = Number.isNaN(targetArr[i]) ? 0 : targetArr[i];
      return sourceArr[i] - targetArr[i];
    }
  }
  return 0;
}
export var getSearchBalance = function getSearchBalance() {
  var _window$location$sear;
  var paramBalance = (_window$location$sear = window.location.search) === null || _window$location$sear === void 0 ? void 0 : _window$location$sear.split(/[?&]/).find(function (item) {
    return item.startsWith('cash=');
  });
  return paramBalance ? paramBalance.replace('cash=', '') : '';
};
export var buildUrl = function buildUrl(urlOptions) {
  urlOptions.origin || (urlOptions.origin = window.location.origin);
  urlOptions.query || (urlOptions.query = {});
  var query = urlOptions.query,
    _urlOptions$path = urlOptions.path,
    path = _urlOptions$path === void 0 ? '/' : _urlOptions$path,
    origin = urlOptions.origin;
  var queryString = Object.keys(query).map(function (key) {
    return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(query[key]));
  }).join('&');
  return "".concat(origin).concat(path, "?").concat(queryString);
};