import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.concat.js";
import { GLOBAL_LOCALE_KEY } from '../config';
// 解决 accept-language 有些user-agent返回相同语言的language-code不一致问题
var languageMapping = {
  'zh-HK': 'zh-TW',
  'ko-kr': 'ko',
  'tr-TR': 'tr',
  'it-CH': 'it',
  'it-IT': 'it',
  'vi-vn': 'vi',
  pt: 'pt-BR',
  'pt-br': 'pt-BR',
  'zh-tw': 'zh-TW',
  'fil-PH': 'en-PH'
};
var baseSupportLanguages = ['en', 'th', 'id', 'ur', 'es', 'pt-BR', 'ar', 'ru', 'tr', 'zh'];
var BASE_LANG_CODE = 'en';
export var getLangCode = function getLangCode() {
  var languageCode = BASE_LANG_CODE;
  // 语言从本地获取
  var nLanguage = navigator.language || BASE_LANG_CODE;
  var language = languageMapping[nLanguage] || nLanguage;
  if (baseSupportLanguages.includes(language)) {
    languageCode = language;
  } else if (baseSupportLanguages.includes(language.split('-')[0])) {
    languageCode = language.split('-')[0];
  }
  var langArray = languageCode.split('-');
  if (langArray.length > 1) {
    languageCode = "".concat(langArray[0], "-").concat(langArray[1].toUpperCase());
  }
  return languageCode;
};
export var getCurrentLocale = function getCurrentLocale() {
  return localStorage.getItem(GLOBAL_LOCALE_KEY) || getLangCode();
};
export var isRTL = function isRTL() {
  var lang = getCurrentLocale();
  var RTL_LANGS = ['ar', 'ur', 'pa', 'ps', 'sd'];
  return RTL_LANGS.includes(lang);
};
export var supportRtl = function supportRtl() {
  var languageCode = getCurrentLocale();
  // 适配rtl
  if (['ar', 'ur', 'pa', 'ps', 'sd'].includes(languageCode)) {
    var className = document.body.className;
    document.body.className = "".concat(className, " rtl");
  }
};
export var supportRtlV2 = function supportRtlV2() {
  var languageCode = getCurrentLocale();
  // 适配rtl
  if (['ar', 'ur', 'pa', 'ps', 'sd'].includes(languageCode)) {
    document.body.setAttribute('dir', 'rtl');
  } else {
    document.body.setAttribute('dir', 'ltr');
  }
};