export var GLOBAL_LOCALE_KEY = 'kwai_locale';
export var langsList = [{
  value: 'en',
  label: 'English',
  language: 'en-us'
}, {
  value: 'pt-BR',
  label: 'Português',
  language: 'pt-br'
}, {
  value: 'ar',
  label: 'بالعربية',
  language: 'ar-ar'
}, {
  value: 'es',
  label: 'Español',
  language: 'es-es'
}, {
  value: 'zh',
  label: '简体中文',
  language: 'zh-cn'
}
// { value: 'id', label: 'Bahasa', language: 'id-id' },
];

export var sid = 'kwaipro.liveguild.h5';