import Vue from 'vue';
import { createK18n } from '@k18n/vue2-k18n';
import k18nPkg from '@k18n/wallet-k18n';
import { getTrinityLangCode } from '@/utils/getLanguage';
import { isInThirdRecharge } from '@/utils/env';
import { getCurrentLocale } from '@/pages/recharge/utils/getLanguage';
var getLocaleLang = function getLocaleLang() {
  if (isInThirdRecharge) {
    return getCurrentLocale();
  }
  return getTrinityLangCode();
};
export var i18n = createK18n(Vue, {
  locale: getLocaleLang(),
  messages: k18nPkg
});
export function useK18n() {
  return i18n.$k18n;
}