export default {
  China: 'zh_CN',
  Taiwan: 'zh_TW',
  England: 'en',
  Korea: 'ko',
  Japan: 'ja',
  Thailand: 'th',
  Malaysia: 'ms',
  Indonesia: 'in',
  Russia: 'ru',
  Philippines: 'en_PH',
  Brazil: 'br',
  Vietnam: 'vnm',
  Myanmar: 'mmr',
  India: 'ind',
  Germany: 'deu',
  Turkey: 'tur',
  French: 'fra',
  Spanish: 'spa',
  Arabic: 'ara',
  Mix: 'mix',
  Egypt: 'egy',
  PAK: 'pak',
  BGD: 'bgd',
  HONG_KONG: 'zh_HK' // 香港
};