import Vue from 'vue';
import Toast from './toast.vue';
var ToastConstructor = Vue.extend(Toast);
var createNotice = function createNotice() {
  var el = document.createElement('div');
  document.body.appendChild(el);
  var vm = new ToastConstructor().$mount(el);
  return {
    addNotice: function addNotice(notice) {
      vm.addNotice(notice);
    }
  };
};
var noticeIns = null;
var notice = function notice(type, text) {
  var duration = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2000;
  if (!noticeIns) {
    noticeIns = createNotice();
  }
  return noticeIns.addNotice({
    type: type,
    text: text,
    duration: duration
  });
};
export default {
  info: function info(text) {
    var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2000;
    return notice('info', text, duration);
  }
};