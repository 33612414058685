import _objectSpread from "/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import { defineComponent, reactive, toRefs } from '@vue/composition-api';
var TOAST_TYPE;
(function (TOAST_TYPE) {
  TOAST_TYPE["INFO"] = "info";
  TOAST_TYPE["SUCCESS"] = "success";
  TOAST_TYPE["FAIL"] = "fail";
})(TOAST_TYPE || (TOAST_TYPE = {}));
export default defineComponent({
  name: 'top-bar',
  setup: function setup() {
    var state = reactive({
      notices: []
    });
    var getUniqKey = function getUniqKey() {
      return "trinity-notice-".concat(new Date(), "-").concat(state.notices.length);
    };
    var removeNotice = function removeNotice(key) {
      state.notices = state.notices.filter(function (item) {
        return item.key !== key;
      });
    };
    var addNotice = function addNotice(notice) {
      var key = getUniqKey();
      notice.key = key;
      state.notices.push(notice);
      if (notice.duration) {
        setTimeout(function () {
          removeNotice(key);
        }, notice.duration);
      }
      return function () {
        return removeNotice(key);
      };
    };
    return _objectSpread({
      addNotice: addNotice
    }, toRefs(state));
  }
});