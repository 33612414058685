import Cookies from 'js-cookie';
var SWIM_LANE_COOKIE = 'overseaLaneId';
var swimLaneId = Cookies.get(SWIM_LANE_COOKIE);
// 获取泳道header，非staging环境返回空对象
export var getSwimLaneHeader = function getSwimLaneHeader() {
  if (process.env.VUE_APP_ENV !== 'staging') {
    return {};
  }
  // 没有泳道cookie，不额外添加header属性
  if (!swimLaneId) {
    return {};
  }
  return {
    'trace-context': "{\"laneId\":\"".concat(swimLaneId, "\"}")
  };
};