import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.concat.js";
// 解决 accept-language 有些user-agent返回相同语言的language-code不一致问题
var languageMapping = {
  'zh-HK': 'zh-TW',
  'ko-kr': 'ko',
  'tr-TR': 'tr',
  'it-CH': 'it',
  'it-IT': 'it',
  'vi-vn': 'vi',
  'pt': 'pt-BR',
  'pt-br': 'pt-BR',
  'pt-PT': 'pt-BR',
  'zh-tw': 'zh-TW',
  'fil-PH': 'en-PH'
};
var baseSupportLanguages = ['en', 'th', 'id', 'ur', 'es', 'pt-BR', 'pt-PT', 'ar', 'ru', 'tr', 'zh'];
var RTL_LANGS = ['ar', 'ur', 'pa', 'ps', 'sd'];
var BASE_LANG_CODE = 'en';
export var getLangCode = function getLangCode() {
  var languageCode = BASE_LANG_CODE;
  // 语言从本地获取
  var nLanguage = navigator.language || BASE_LANG_CODE;
  var language = languageMapping[nLanguage] || nLanguage;
  if (baseSupportLanguages.includes(language)) {
    languageCode = language;
  } else if (baseSupportLanguages.includes(language.split('-')[0])) {
    languageCode = language.split('-')[0];
  }
  var langArray = languageCode.split('-');
  if (langArray.length > 1) {
    languageCode = "".concat(langArray[0], "-").concat(langArray[1].toUpperCase());
  }
  // 适配rtl
  if (RTL_LANGS.includes(languageCode)) {
    var className = document.body.className;
    document.body.className = "".concat(className, " rtl");
  }
  console.log("language: ".concat(languageCode));
  return languageCode;
};
export var getTrinityLangCode = function getTrinityLangCode() {
  var languageCode = BASE_LANG_CODE;
  // 语言从本地获取
  var nLanguage = navigator.language || BASE_LANG_CODE;
  var language = languageMapping[nLanguage] || nLanguage;
  if (baseSupportLanguages.includes(language)) {
    languageCode = language;
  } else if (baseSupportLanguages.includes(language.split('-')[0])) {
    languageCode = language.split('-')[0];
  }
  var langArray = languageCode.split('-');
  if (langArray.length > 1) {
    languageCode = "".concat(langArray[0], "-").concat(langArray[1].toUpperCase());
  }
  console.log('languageCode: ', languageCode);
  // 适配rtl
  if (RTL_LANGS.includes(languageCode)) {
    document.documentElement.setAttribute('dir', 'rtl');
  } else {
    document.documentElement.setAttribute('dir', 'ltr');
  }
  return languageCode;
};
export var isRTL = function isRTL() {
  var lang = getLangCode();
  return RTL_LANGS.includes(lang);
};