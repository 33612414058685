import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.concat.js";
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { encodeURI } from 'js-base64';
import utc from 'dayjs/plugin/utc';
import { isKwaiMe } from '@/utils/env';
import { getAppVersion } from '@kibt/web-utils/device-info';
import { versionCompare } from '@kibt/web-utils/version';
import { timeFormatType, timezone, currencyFormatType, userId } from './const';
import { yodaBridge } from './yoda';
dayjs.extend(utc);
export function toFixedWithOutRounding(val) {
  var n = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var re = new RegExp("^\\d+(?:\\.\\d{0,".concat(n, "})?"));
  return Number(val.toString().match(re));
}
// <10000 正常展示  10000<=x <1000000    >= 100万 m单位  10亿 b
export function formateNum(num, fixedNumber) {
  if (num >= 1000000000) {
    return "".concat(toFixedWithOutRounding(num / 1000000000, fixedNumber), "B");
  }
  if (num >= 1000000) {
    return "".concat(toFixedWithOutRounding(num / 1000000, fixedNumber), "M");
  }
  if (num >= 10000) {
    return "".concat(toFixedWithOutRounding(num / 1000, fixedNumber), "K");
  }
  return num;
}
// 版本对比
// vStr1 > vStr2 return 1
// vStr1 < vStr2 return -1
// vStr1 = vStr2 return 0
export function compareVersion(vStr1, vStr2) {
  var v1 = vStr1.split('.');
  var v2 = vStr2.split('.');
  var len = Math.max(v1.length, v2.length);
  while (v1.length < len) {
    v1.push('0');
  }
  while (v2.length < len) {
    v2.push('0');
  }
  for (var i = 0; i < len; i++) {
    var num1 = parseInt(v1[i], 10);
    var num2 = parseInt(v2[i], 10);
    if (num1 > num2) {
      return 1;
    }
    if (num1 < num2) {
      return -1;
    }
  }
  return 0;
}
export function dateFormat(date) {
  var formatStr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : timeFormatType;
  if (timezone) {
    return dayjs.utc(Number(date) - timezone * 3600 * 1000).format(formatStr);
  }
  return dayjs(Number(date)).format(formatStr);
}
// 本地格式化
export var toLocaleString = function toLocaleString(value) {
  var locales = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : currencyFormatType;
  return value.toLocaleString(locales || currencyFormatType);
};
/**
 *  Cash&Points数值仅能展示六位数
 * Cash数值缩写：
 * 默认保留两位小数，最多展示至R$9999.99
 * 超过四位数，截位，保留一位小数，最多展示至R$99999.9
 * 超过五位数，截位，取整，最多展示至R$999999
 * 后续不做处理
 * @param val
 */
export var fomateCash = function fomateCash(val) {
  var locales = isKwaiMe ? 'en-US' : '';
  if (val < Math.pow(10, 4)) {
    return toLocaleString(val, locales);
  }
  if (val < Math.pow(10, 5)) {
    return toLocaleString(toFixedWithOutRounding(val, 1), locales);
  }
  if (val < Math.pow(10, 6)) {
    return toLocaleString(toFixedWithOutRounding(val, 0), locales);
  }
  if (val < Math.pow(10, 9)) {
    return "".concat(toLocaleString(toFixedWithOutRounding(val / Math.pow(10, 3), 0), locales), "K");
  }
  return "".concat(toLocaleString(999999, locales), "K");
};
/**
 * 格式化金币展示
 * @param val
 */
export var fomatePoints = function fomatePoints(val) {
  var locales = isKwaiMe ? 'en-US' : '';
  if (val < Math.pow(10, 6)) {
    return toLocaleString(val, locales);
  }
  if (val < Math.pow(10, 10)) {
    return "".concat(toLocaleString(toFixedWithOutRounding(val / Math.pow(10, 6), 2), locales), "M");
  }
  if (val < Math.pow(10, 11)) {
    return "".concat(toLocaleString(toFixedWithOutRounding(val / Math.pow(10, 6), 1), locales), "M");
  }
  if (val < Math.pow(10, 12)) {
    return "".concat(toLocaleString(toFixedWithOutRounding(val / Math.pow(10, 6), 0), locales), "M");
  }
  return "".concat(toLocaleString(999999, locales), "M");
};
// 跳转到端外
export var jumpOuterWallet = function jumpOuterWallet(path) {
  var _yodaBridge$tool;
  // TODO: 不同端是否不通
  var keys = ['kwaipro.api_st', 'countryInfo', 'appName', 'sys', 'bucket', 'kuaishou.oversea.im_st', 'appver', 'userId', 'kpn', 'passToken', 'token', 'did', 'overseaLaneId', 'trace_context'];
  var r = '';
  keys.forEach(function (k) {
    r += "".concat(k, "=").concat(Cookies.get(k), ";");
  });
  // const ck = encode(document.cookie);
  var ck = encodeURI(r);
  var url = "".concat(window.location.origin).concat(path, "?source=transfer&ck=").concat(ck);
  yodaBridge === null || yodaBridge === void 0 ? void 0 : (_yodaBridge$tool = yodaBridge.tool) === null || _yodaBridge$tool === void 0 ? void 0 : _yodaBridge$tool.launchApp({
    scheme: url
  });
};
// 判断浏览器是否支持webp 自动替换url后缀
export var checkSupportWebp = function checkSupportWebp() {
  var support = false;
  var isWebViewSupportWebp = localStorage.getItem('isWebViewSupportWebp');
  // 检查到webView支持webp，则从storage中读取，避免多次对canvas的生成，影响性能
  // 若不支持，canvas判断只执行1次，避免重复 toDataURL，造成多余CPU消耗
  // fix@2020/8/5: localStorage取出来是字符串!
  if (isWebViewSupportWebp === 'true') {
    return true;
  }
  if (isWebViewSupportWebp === 'false') {
    return false;
  }
  try {
    // 判断浏览器是否支持webp
    if (/data:image\/webp/gi.test(document.createElement('canvas').toDataURL('image/webp'))) {
      // 支持webp
      support = true;
    } else {
      // 不支持webp
      support = false;
    }
  } catch (err) {
    // 不支持webp
    support = false;
  }
  localStorage.setItem('isWebViewSupportWebp', String(support));
  return support;
};
export function isSupportKSwitch() {
  var version = getAppVersion();
  var res = versionCompare(version, '5.7.0');
  return res >= 0;
}
// 前端记录是否完成过某动作
export var isVisited = function isVisited(key) {
  var enterKey = "".concat(key, "_").concat(userId);
  var visited = localStorage.getItem(enterKey) === 'ENTER';
  return [visited, function () {
    localStorage.setItem(enterKey, 'ENTER');
  }];
};
/**
 * 复制函数
 */
export var copy = function copy(text) {
  // const value = typeof text === 'function' ? await text() : text;
  var value = text;
  // copy start
  var input = document.createElement('input');
  input.setAttribute('value', value);
  document.body.appendChild(input);
  input.select();
  document.execCommand('copy');
  document.body.removeChild(input);
};