import throttle from 'lodash/throttle';
var onScroll = null;
function handleScroll(cb) {
  var clientHeight = document.documentElement.clientHeight;
  var offsetHeight = document.body.offsetHeight;
  var scrollTop = window.scrollY + 200;
  if (clientHeight + scrollTop >= offsetHeight) {
    cb();
  }
}
var directive = {
  inserted: function inserted(el, binding) {
    var cb = binding.value;
    if (typeof cb !== 'function') return;
    cb();
    onScroll = throttle(handleScroll.bind(el, cb), 200);
    window.addEventListener('scroll', onScroll);
  },
  unbind: function unbind() {
    if (typeof onScroll === 'function') {
      window.removeEventListener('scroll', onScroll);
    }
  }
};
var install = function install(Vue) {
  Vue.directive('infinite-scroll', directive);
};
export default {
  install: install
};