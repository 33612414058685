var _getQuery;
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import cookies from 'js-cookie';
import { compareAppVersion, getQuery } from './util';
var kpn = cookies.get('kpn');
var ua = navigator.userAgent;
export var isKwai = kpn === 'KWAI';
export var isSnack = kpn === 'KWAI_BULLDOG';
export var isKwaiMe = kpn === 'KWAI.ME';
export var isInApp = ['KWAI', 'KWAI_BULLDOG', 'KWAI.ME'].includes(kpn) && !!window.__yodaBridge__ || ((_getQuery = getQuery()) === null || _getQuery === void 0 ? void 0 : _getQuery.debug_web) === 'true';
// 旧的三方充值平台https://pay.kwai.com/kwai/recharge和新的三方充值平台https://m-wallet.staging.kuaishou.com/recharge/index均可访问
export var isInThirdRecharge = ['pay.kwai.com', 'pay.kslawin.com', 'pay-pre.corp.kuaishou.com', 'kwai-recharge.staging.kuaishou.com'].includes(window.location.host) || ['/recharge/index', '/recharge/payResult'].includes(window.location.pathname);
export var isIOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
export var isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1;
export var isProduction = process.env.NODE_ENV === 'production';
export var mProKwaiOrigin = process.env.HOST || window.location.origin;
export var isLogin = function isLogin() {
  return !!(cookies.get('token') || cookies.get('ud'));
};
var appver = cookies.get('appver') || '1.0.0';
export var isIOSNewVersion = compareAppVersion(appver, '5.10.10') >= 0 && isIOS;
export var bucket = cookies.get('bucket');
export var isToOuter = isIOS && isInApp && isIOSNewVersion;